<template>
    <section class="shift">      <!-- MODIFY -->

        <div class="row d-flex flex-row justify-content-end"> 
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">shift</li>   <!-- MODIFY -->
                    <li class="breadcrumb-item active" aria-current="page"><router-link class="" v-bind:to="'/g1/first'+'?t='+$root._SYS().add_v()"  >first</router-link></li>  
                </ol>
            </nav>
        </div>

        <div class="row"> 

            <div class="col-xl-12 col-lg-12 col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">

                        <h4>_route_= {{ this.$store.state._route_ }}</h4>


                        


                    </div> 

                    <div class='card-body'  >   <!-- index.js  is_required_stages -->

                        <div>
                            shift to class {{ $store.state.state_tables.state_auth.r_auth['classnum'] }} and lid {{ $store.state.state_tables.state_auth.r_auth['lid'] }}
                        </div>

                        <div>
                            <router-link class="pl-4 pb-4" v-bind:to="'/g1/first/'+'?t='+$root._SYS().add_v()"  >first</router-link>
                        </div>

                        <div class="d-flex flex-column align-items-start">
                            <div><b>[auth]</b></div> 
                            <!-- <div>rid: {{ $store.state.state_tables.state_auth.r_auth['rid'] }}</div>-->
                            <div>classnum: {{ $store.state.state_tables.state_auth.r_auth['classnum'] }}</div>
                            <div>lid: {{ $store.state.state_tables.state_auth.r_auth['lid'] }}</div>
                            <!--   <div>mysql_v: {{ $store.state.state_tables.state_auth.r_auth['mysql_v'] }}</div>-->
                            
                        </div>




 
                       
                           <b-button variant="primary" class=" btn" v-on:click="move_to_first('/g1/first/')" v-bind:disabled='!$store.state.system[this.cdata.system._route_].is_mounted_execute'>return to first</b-button>
                      
                           <!--     
                           <b-button variant="primary" class=" btn" v-on:click="test_btn" v-bind:disabled='cdata.system.buttons.button1.is_button_blocked'>test_btn (button1)</b-button>
                        -->




                    </div> 
                  



                </div>





            </div>   

        </div>





    </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
           
            
              var cdata = {//Если не указать - не всключиться реактивность 

                system: {
                    _route_: "g1_shift", /* MODIFY */
                    cnf: {}, 
                },
            
                business_logic: {
                
                },
            }

    export default {
        name: 'shift', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() { 
            this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': false, '_route_': this.cdata.system._route_ });
          
                
                //TODO? УДАЛИТЬ.... таблицы из store
                
                //TODO? Очистить данные из state_desk
             
              this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": true});
                   
              this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
        },
        computed: {

           

        },

        watch: {

           
        },

        methods: {

     
            //load переименовать 
            
             move_to_first: function (new_route) {

              // this.$store.dispatch('_system/vuex_set__is_active_timer_check_shift', {"is_active_timer_check_shift": true});
                   
          
                this.$root._PAGE().move_to(this.$store, this.cdata, new_route);
               

            },

         

        }


    }
</script>

<style scoped>
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: 0px !important;
        }
    }
</style>
